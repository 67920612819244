.slick-slider .slick-dots {
  bottom: 12px;
  right: 0;
  left: 0;
  display: flex !important;
  justify-content: center;
}

.slick-dots li,
.slick-dots li button {
  transition: all 0.5s;
  height: 3px;
}

.slick-dots li {
  width: 16px;
}

.slick-dots li button {
  width: 100%;
  padding: 0;
  background: #fff;
  opacity: 1;
  border-radius: 1px;
  opacity: 0.3;

  &:hover, &:focus {
    opacity: 0.75;
  }
}

.slick-dots li.slick-active {
  width: 24px;
  button {
    opacity: 1;
  }
}
