// CSS custom properties. This file should be only be imported once per Rails route
// (additional imports will cause it to be duplicated in the compiled CSS)

:root {
  /* Define basic common colors in variations best aligning 
   * to our brand.
   */

  /* Colors with multiple shades may not progress along the lightness
   * scale without having their hue or saturation change. However, 
   * we'll use a 0-100 scale of 'shades' to represent these colors.
   * They will be grouped as closely to their hue as makes sense for
   * their use, and shades represented proportionally based on their 
   * location relative to each other. When two conflict (same lightness), 
   * the most 'correct' saturated value should use the name, and the
   * less correct one should have a saturation level appended.
   *
   * The 'core' color for each group (without numbers) will be the
   * shade selected as most useful/most used, not the -0 or -100 shade.
   */

  --c_white: #ffffff;
  --c_black: #000000;

  --c_neutral-0: #000000; // Don't use '--c_black'
  --c_neutral-4: #0a0a0a;
  --c_neutral-32: #515151;
  --c_neutral-47: #777777;
  --c_neutral-50: #808080;
  --c_neutral-54: #8a8a8a;
  --c_neutral-65: #a5a5a5;
  --c_neutral-70: #b2b2b2;
  --c_neutral-73: #bababa;
  --c_neutral-85: #d8d8d8;
  --c_neutral-86: #dcdcdc;
  --c_neutral-93: #ededed;
  --c_neutral-95: #f2f2f2;
  --c_neutral-97: #f8f8f8;
  --c_neutral-99: #fcfcfc;
  --c_neutral-100: #ffffff; // Don't use '--c_white'

  --c_chalk-98: #fcfcfa;
  --c_chalk: var(--c_chalk-98);

  --c_gold-50: #ffb800;
  --c_gold-95: #fff9e8;
  --c_gold-97: #fff9f2;
  --c_gold: var(--c_gold-50);

  --c_yellow-98: #fafaf8;
  --c_yellow: var(--c_yellow-98);

  --c_green-23: #22543d;
  --c_green-23-33: #274e40;
  --c_green-34: #348483;
  --c_green-44: #4a957a;
  --c_green-60: #6fc39a;
  --c_green-80: #8dd8cf;
  --c_green-95: #f1fbec;
  --c_green-96: #f7fcf0;
  --c_green-96-10: #f5f6f4;
  --c_green: var(--c_green-44);

  --c_moss: var(--c_green-44);
  --c_moss_bright: #dcf836;

  --c_lime-55: #c3d245;
  --c_lime-59: #dbf835;
  --c_lime-97: #fafcf2;
  --c_lime: var(--c_lime-59);

  --c_blue-59: #364af9;
  --c_blue-95: #edf5fa;
  --c_blue-96: #f0f5fa;
  --c_blue: var(--c_blue-59);
  /* This should probably be our primary blue */
  --c_lapis-53: #484ec8;
  --c_lapis-59: #364af9;
  --c_lapis-95: #edf5fa;
  --c_lapis: var(--c_lapis-53);

  --c_orange-50: #ff9401;
  --c_orange-63: #ff7043;
  --c_orange-96: #fcf4f0;
  --c_orange: var(--c_orange-63);
  --c_tangerine: var(--c_orange-63);
  --c_off_orange: #faf6ed;
  --c_light_orange: #fff9f0;

  --c_red-55: #ff1850;
  --c_red: var(--c_red-55);

  --c_chalk-98: #fcfcfa;
  --c_chalk: var(--c_chalk-98);

  /* Define colors to be used based on the generic use of each color.
   */

  --c_brand: var(--c_green);

  --c_background: var(--c_yellow-98);

  --c_text: var(--c_neutral-4);
  --c_text-secondary: var(--c_neutral-47);

  --c_disabled: var(--c_neutral-65);


  /* New colors (from Core Components) 
     TODO: these need to be disambiguated from previous colors (scale is different)
   */
  /* All color key base color values, using 'color' names.
    https://www.figma.com/file/IX2w5tHx8eb5Zut5S23T5w/Beam-Foundations?node-id=3585%3A62200&t=CKnjWjPdJ69n4YJa-0
   */
  // --c_white: #FFFFFF;
  --c_neutrals-0: #FFFFFF;
  --c_neutrals-100-RGB-values: 255, 255, 255; // Match to --c_neutrals-0
  --c_neutrals-100: #F9FAFA;
  --c_neutrals-200: #EEEFF1;
  --c_neutrals-300: #DEDFE3;
  --c_neutrals-400: #BFC2C9;
  --c_neutrals-500: #999EA9;
  --c_neutrals-600: #5E626E;
  --c_neutrals-700: #3E4148;
  --c_neutrals-800: #2B2D33;
  --c_neutrals-900: #1C1E21;
  --c_neutrals-1000: #121316;
  --c_neutrals-1000-RGB-values: 18, 19, 22; // Match to --c_neutrals-1000
  --c_neutrals-1100: #000000;

  --c_moss-100: #F6FAF8;
  --c_moss-200: #DBE4DF;
  --c_moss-300: #B7CBC0;
  --c_moss-400: #91B2A2;
  --c_moss-500: #689B82;
  --c_moss-600: #318562;
  --c_moss-700: #146B4B;
  --c_moss-800: #0F5038;
  --c_moss-900: #0A3726;
  --c_moss-1000: #061f16;

  --c_lapis-100: #F2F3FA;
  --c_lapis-200: #DEE0FD;
  --c_lapis-300: #BEC2FB;
  --c_lapis-400: #9EA3F9;
  --c_lapis-500: #7D85F8;
  --c_lapis-600: #5966F7;
  --c_lapis-700: #3648E6;
  --c_lapis-800: #2936AD;
  --c_lapis-900: #1C2577;
  --c_lapis-1000: #101545;

  --c_sonne-100: #FCF6F2;
  --c_sonne-200: #F9ECE4;
  --c_sonne-300: #F3D9C7;
  --c_sonne-400: #ECC3A5;
  --c_sonne-500: #E6AB78;
  --c_sonne-600: #E08F2D;
  --c_sonne-700: #C87F28;
  --c_sonne-800: #AD6E22;
  --c_sonne-900: #8D5A1C;
  --c_sonne-1000: #643F14;

  --c_crimson-100: #FBF3F4;
  --c_crimson-200: #F7DCDD;
  --c_crimson-300: #F0B8BA;
  --c_crimson-400: #EA9396;
  --c_crimson-500: #E46A70;
  --c_crimson-600: #E0303E;
  --c_crimson-700: #B4232F;
  --c_crimson-800: #891A24;
  --c_crimson-900: #5F1219;
  --c_crimson-1000: #380B0E;

  /* All color key named colors.
   */
  --c_primary-100: var(--c_moss-100);
  --c_primary-600: var(--c_moss-600);
  --c_primary-700: var(--c_moss-700);
  --c_primary-800: var(--c_moss-800);
  --c_primary-900: var(--c_moss-900);

  --c_secondary-100: var(--c_lapis-100);
  --c_secondary-200: var(--c_lapis-200);
  --c_secondary-600: var(--c_lapis-600);
  --c_secondary-700: var(--c_lapis-700);
  --c_secondary-800: var(--c_lapis-800);
  --c_secondary-900: var(--c_lapis-900);

  --c_success-100: var(--c_moss-100);
  --c_success-700: var(--c_moss-700);
  --c_success-800: var(--c_moss-800);
  --c_success-900: var(--c_moss-900);

  --c_warning-100: var(--c_sonne-100);
  --c_warning-700: var(--c_sonne-700);
  --c_warning-800: var(--c_sonne-800);
  --c_warning-900: var(--c_sonne-900);

  --c_danger-100: var(--c_crimson-100);
  --c_danger-600: var(--c_crimson-600);
  --c_danger-700: var(--c_crimson-700);
  --c_danger-800: var(--c_crimson-800);
  --c_danger-900: var(--c_crimson-900);

  /* Color styles specialized colors
     https://www.figma.com/file/IX2w5tHx8eb5Zut5S23T5w/Beam-Foundations?node-id=4%3A2&t=CKnjWjPdJ69n4YJa-0
   */
  --c_element-primaryBlack: rgba(var(--c_neutrals-1000-RGB-values), 1.0);
  --c_element-secondaryBlack: rgba(var(--c_neutrals-1000-RGB-values), 0.8);
  --c_element-tertiaryBlack: rgba(var(--c_neutrals-1000-RGB-values), 0.65);
  --c_element-disabledBlack: rgba(var(--c_neutrals-1000-RGB-values), 0.55);
  --c_element-primaryWhite: rgba(var(--c_neutrals-100-RGB-values), 1.0);
  --c_element-secondaryWhite: rgba(var(--c_neutrals-100-RGB-values), 0.75);
  --c_element-tertiaryWhite: rgba(var(--c_neutrals-100-RGB-values), 0.6);
  --c_element-disabledWhite: rgba(var(--c_neutrals-100-RGB-values), 0.5);

  --c_background-primary: var(--c_neutrals-0);
  --c_background-base: var(--c_neutrals-100);
  --c_background-secondary: var(--c_neutrals-200);

  --c_line: var(--c_neutrals-300);

  // Total outlier...
  --c_neutralsCharcoal: #0A0A0A;


  /* Define general use fonts
   */

  --f_monospace: 'ABCDiatypeMono', 'Courier New', Courier, monospace;
  --f_moderat: 'Moderat', sans-serif;
  --f_optima: 'Optima', sans-serif;
  --f_open-sans: 'OpenSans', sans-serif;
}